<template>
  <div style="flex: 1;" class="flix-flex flix-flex-list">
    <a
      v-for="(date, index) in newDates"
      :key="date[0]"
      :style="getPadding(index)"
      href="#"
      @click.prevent="setPause(date, index)"
      class="flix-gap-5 flix-flex flix-flex-center flix-html-a"
    >
      <div
        class="flix-gap-10 flix-flex flix-flex-list flix-flex-center"
        style="flex: 1; text-align: center; max-width: 200px"
        :class="{ 'flix-bg-success': date[2] }"
        :style="getStyle(date)"
      >
        <div
          style="text-align: center"
          :class="{ 'flix-bg-success': true }"
          class="flix-html-small"
          v-if="date[2]"
        >
          {{ date[0] }}<br />–<br />{{ date[1] }}
        </div>
        <div class="icon">
          <icon id="circle-remove" v-if="date[2]" />
          <span class="flix-text-grey" v-else>
            <icon id="circle-plus" />
          </span>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    dates: Array,
    weekday: [Number, String]
  },
  data() {
    return {
      padding: this.calculatePadding(),
      newDates: JSON.parse(JSON.stringify(this.dates))
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getPadding(index) {
      if (index === 0) {
        return false;
      }
      return "padding-top: " + this.padding + "px";
    },
    calculatePadding() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      var weekday = this.weekday * 1;
      var includeWeekday = data.include.weekdays[weekday][2];
      var nextAppointments = data.next_appointments[weekday];

      if (nextAppointments <= includeWeekday) {
        return 0;
      }

      var calc = nextAppointments * 1 - includeWeekday * 1;
      calc = calc / 15;

      calc = calc * 60 + calc * 10;

      return calc;
    },
    getStyle() {
      var weekday = this.weekday * 1;
      var includeWeekday = JSON.parse(
        JSON.stringify(this.$store.state.business.unsaved)
      ).include.weekdays[weekday];

      var height = (includeWeekday[2] * 1) / 15;

      return { height: height * 60 + (height - 1) * 8 + "px" };
    },
    setPause(date, index) {
      var weekday = this.weekday * 1;
      var excludeWeekday = this.$store.state.business.unsaved.exclude.weekdays;

      var saved = false;

      if (typeof excludeWeekday[weekday] !== "undefined") {
        Object.keys(excludeWeekday[weekday]).forEach(function(t) {
          if (
            excludeWeekday[weekday][t][0] === date[0] &&
            excludeWeekday[weekday][t][1] === date[1]
          ) {
            excludeWeekday[weekday].splice(t, 1);
            saved = true;
          }
        });
      }

      if (!saved) {
        if (typeof excludeWeekday[weekday] === "undefined") {
          excludeWeekday[weekday] = [[date[0], date[1]]];
        } else {
          excludeWeekday[weekday].push([date[0], date[1]]);
        }
      }

      // delete this.newDates[index]
      // this.newDates = Object.values(this.newDates)
      this.newDates[index][2] = !this.newDates[index][2];

      this.setSave(excludeWeekday);
    },
    setSave(ret) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.exclude.weekdays = ret;
      this.$store.commit("business/prefetch", data);
      // this.$store.commit('tableau/setUpdate')
    }
  }
};
</script>
<style lang="sass" scoped>
.flix-html-a
  width: 100%
  box-sizing: border-box
  padding: 4px 0
  // flex: 1
  font-size: 10pt
  color: white
.icon
  width: 25px
  font-size: 18pt
  span.flix-text-grey
    color: grey!important
</style>
